export const DEFAULT_ZOOM = 10
export const CITY_ZOOM = 11.5
export const FOCUS_ZOOM = 13
const FIT_BOUND_OPTIONS = {
    padding: 50,
    maxZoom: FOCUS_ZOOM
}

export default class MapboxManager {
    // constructor(mapContainerId) {
    //     this.mapContainerId = mapContainerId;
    // }

    async initMap(options) {
        // mapboxgl.accessToken = apiKey;
        mapboxgl.accessToken = 'pk.eyJ1IjoiamFtaWVwaXR0b2NrIiwiYSI6ImNsajV2NXl1YTBnYWozY3BhMDgyMTZzb3YifQ.dzDLX49TqApgq0xDGhB4Lw';

        const map = await loadMap(options);
        return map;
    }

    addPoints(map, points) {
        this.addImageToMap(map, "marker-standard", "/dist/mapbox/marker/standard.svg");
        this.addImageToMap(map, "marker-standard-active", "/dist/mapbox/marker/standard-active.svg");

        let hoveredFeatureId = null;

        let data = {
            type: 'FeatureCollection',
            features: points.map(pt => pt.toGeoJsonFeature())
        }

        map.addSource('points', {
            type: 'geojson', 
            cluster: true,
            clusterMaxZoom: 10,  // Max zoom to cluster points on
            clusterRadius: 50,  // Radius of each cluster when clustering points (defaults to 50)
            data
        });

        map.addLayer({
            id: 'points',
            type: 'symbol',
            source: 'points',
            filter: ['!', ['has', 'point_count']],
            layout: {
                "icon-allow-overlap": !0,
                "icon-anchor": "bottom",
                "icon-image": ["match", 
                    ["get", "category"], 
                    "submit", "marker-standard", 
                    "marker-standard"
                ]
            }
        });

        map.addLayer({
            id: 'points-active',
            type: 'symbol',
            source: 'points',
            filter: ['!', ['has', 'point_count']],
            layout: {
                "icon-allow-overlap": !0,
                "icon-anchor": "bottom",
                "icon-image": "marker-standard-active",
            },
            'paint': {
                'icon-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    1,  // fill color when hover is true
                    0  // fill color when hover is false
                ]
            }
        });
    
        map.addLayer({
            id: 'clusters-active',
            type: 'circle',
            source: 'points',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step) to smoothly scale the size of the clusters from small to large
                'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                'circle-color': ['step', ['get', 'point_count'], '#999', 100, '#999', 750, '#999']
            }
        });

        map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'points',
            filter: ['has', 'point_count'],
            paint: {
                // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step) to smoothly scale the size of the clusters from small to large
                'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
                'circle-color': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    '#999',  // fill color when hover is true
                    '#fff'  // fill color when hover is false
                ]
            }
        });

        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'points',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 12
            }
        });


        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        map.on('click', 'points', (e) => {
            // Copy coordinates array.
            const coordinates = e.features[0].geometry.coordinates.slice();
            const description = e.features[0].properties.name;
            const id = e.features[0].properties.ptid;

            const content = document.querySelector('[data-id="' + id + '"]');
            // const content = document.getElementById(id);

            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }

            new mapboxgl.Popup({
                    offset: [0, -25],
                    closeButton: false
                })
                .setLngLat(coordinates)
                .setHTML(
                    '<div class="fell-card fell-card--v fell-card--popup">' +
                    content.innerHTML +
                    '</div>'
                )
                .addTo(map);
        });

        map.on('click', 'clusters', (e) => {
            const features = map.queryRenderedFeatures(e.point, {
                layers: ['clusters']
            });
            const clusterId = features[0].properties.cluster_id;

            map.getSource('points').getClusterExpansionZoom(
                clusterId,
                (err, zoom) => {
                    if (err) return;

                    map.easeTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                }
            );

        });

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on('mousemove', 'points-active', (e) => {
            map.getCanvas().style.cursor = 'pointer';

            if (hoveredFeatureId !== null) {
                map.setFeatureState(
                    { source: 'points', id: hoveredFeatureId },
                    { hover: false }
                );
            }

            // Get the ID of the feature being hovered over
            hoveredFeatureId = e.features[0].id;
            
            // Set a new paint property for the layer using the feature's ID
            map.setFeatureState(
                { source: 'points', id: hoveredFeatureId },
                { hover: true }
            );
        });

        // Change it back to a pointer when it leaves.
        map.on('mouseleave', 'points-active', (e) => {
            map.getCanvas().style.cursor = '';
            
            // Reset the paint property for the layer
            if (hoveredFeatureId !== null) {
                map.setFeatureState(
                    { source: 'points', id: hoveredFeatureId },
                    { hover: false }
                );
            }
            hoveredFeatureId = null;
        });

        map.on('mousemove', 'clusters-active', (e) => {
            map.getCanvas().style.cursor = 'pointer';

            if (hoveredFeatureId !== null) {
                map.setFeatureState(
                    { source: 'points', id: hoveredFeatureId },
                    { hover: false }
                );
            }

            // Get the ID of the feature being hovered over
            hoveredFeatureId = e.features[0].id;
            
            // Set a new paint property for the layer using the feature's ID
            map.setFeatureState(
                { source: 'points', id: hoveredFeatureId },
                { hover: true }
            );
        });

        map.on('mouseleave', 'clusters-active', (e) => {
            map.getCanvas().style.cursor = '';
            
            // Reset the paint property for the layer
            if (hoveredFeatureId !== null) {
                map.setFeatureState(
                    { source: 'points', id: hoveredFeatureId },
                    { hover: false }
                );
            }
            hoveredFeatureId = null;
        });

        // map.on('moveend', function() {
        //     const bbox = map.getBounds().toArray().flat();
        //     const zoom = map.getZoom();
        //     // const clusters = cluster.getClusters(bbox, Math.round(zoom));
        
        //     // Now you can add the clusters to your map as a new source
        //     map.getSource('clusters').setData({ type: 'FeatureCollection', features: data });

        // });
    }

    addImageToMap(map, imageId, imagePath) {
        const image = new Image(35, 45);
        image.onload = function() {
            map.addImage(imageId, image)
        }, image.src = imagePath
    }

    calculateBounds(points) {
        if (points.length == 0) return undefined;
    
        let lats = points.map(p => p.lat)
        let lons = points.map(p => p.lon)
        return [
            { lon: Math.min(...lons), lat: Math.min(...lats) },
            { lon: Math.max(...lons), lat: Math.max(...lats) }
        ]
    }
}

function loadMap(options) {
    const map = new mapboxgl.Map({
        ...options,
        fitBoundsOptions: FIT_BOUND_OPTIONS,
        attributionControl: true
    });

    return new Promise((resolve, _reject) => {
        map.on('load', () => {
            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            const sc = new mapboxgl.ScaleControl();
            map.addControl(sc);

            // const glc = new mapboxgl.GeolocateControl();
            // map.addControl(glc);

            // map.on('click', (ev) => console.log(ev))

            // map.on('zoomstart', () => {
            //     console.log(map.getBounds().toArray());
            // });

            resolve(map)
        })
    })
}