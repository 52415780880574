import { Controller } from '@hotwired/stimulus'

import Point from './point.js'
import { DEFAULT_ZOOM, FOCUS_ZOOM, CITY_ZOOM, mapInit, addPoints, calculateBounds, addDraggableMarker } from './lib'
import { elementOnScreen, debounce } from '../../utils'

// TODO: refactor
class BaseController extends Controller {
    static targets = ['map']
    static values = { apikey: String, styleurl: String, centerlat: Number, centerlon: Number }

    async _mapInit() {
        let map = await mapInit(this.apikeyValue, this.mapOptions)
        this.map = map
        return map
    }

    get zoom() {
        return DEFAULT_ZOOM
    }

    get mapOptions() {
        return {
            container: this.mapTarget,
            style: this.styleurlValue,
            center: this.center,
            zoom: this.zoom
        }
    }

    get center() {
        return {lat: this.centerlatValue, lon: this.centerlonValue}
    }
}

// TODO: rename?
export class MapController extends BaseController {
    static targets = ['point', 'marker', 'buttonRow', 'list']

    connect() {
        this.points = this.pointTargets.map(pt => {
            let lat = Number(pt.dataset.lat),
                lon = Number(pt.dataset.lon)
            return new Point({
                lat, lon,
                category: pt.dataset.category,
                name: pt.dataset.name,
                id: pt.id
            })
        });

        this.pointsMap = new Map(this.points.map(p => [p.id, p]))

        this._mapInit().then(map => addPoints(map, this.points));
    }

    get mapOptions() {
        return Object.assign(super.mapOptions,  {bounds: calculateBounds(this.points)})
    }

    toggleList() {
        let hiddenClass = "hidden"
        this.listTarget.classList.toggle(hiddenClass);

        setTimeout(() => this.map.resize(), 500);
      }

    panTo(ev) {
        console.log('pan');
        ev.preventDefault()
        let point = this.pointsMap.get(ev.target.closest('li').id)
        this.map.flyTo({ center: point, zoom: FOCUS_ZOOM })

        console.log(this.pointsMap);
        console.log(ev.target.closest('li').id);
        console.log(point);

        if (!elementOnScreen(this.mapTarget)) {
            this.mapTarget.scrollIntoView({behavior: "smooth"})
        }
    }

    markerTargetConnected(el) {
        const point = this.pointsMap.get(el.dataset.pointId)

        el.setAttribute('role', 'button')
        el.setAttribute('hx-get', point.links.buttonsRow)
        el.setAttribute('hx-target', '#buttons_row')

        htmx.process(el) // Needed for the HTMX attributes to work
    }

    activateMarker(_ev) {
        this.buttonRowTarget.classList.remove('active')
    }

    postActivateMarker() {
        this.buttonRowTarget.classList.add('active')
    }

    dismissButtonRow() {
        this.buttonRowTarget.classList.remove('active')
    }

    postListRefresh() {

        this.points = this.pointTargets.map(pt => {
            let lat = Number(pt.dataset.lat),
                lon = Number(pt.dataset.lon)
            return new Point({
                lat, lon,
                category: pt.dataset.category,
                name: pt.dataset.name,
                id: pt.id
            })
        });

        this.pointsMap = new Map(this.points.map(p => [p.id, p]));

        let data = {
            type: 'FeatureCollection',
            features: this.points.map(pt => pt.toGeoJsonFeature())
        }

        const pointsSource = this.map.getSource('points');
        pointsSource.setData(data);

        if (this.points.length === 0) { 
            return;
        }

        this.map.fitBounds(calculateBounds(this.points), {
            padding: 100
        });
    
        console.log(this.points);
        // addPoints(this.map, this.points)
    }

    resetFilters() {       
        console.log('reset'); 
        // htmx.trigger('#map-component', 'refresh', {reset: 1, monkey: "yes"});
        htmx.find('#filter-search').value = '';
        htmx.find('#filter-area').value = '';
        htmx.find('#filter-difficulty').value = '';
    }
}