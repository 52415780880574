import styles from './marker-styles.module.css'
import icons from './icons'

export const DEFAULT_ZOOM = 10
export const CITY_ZOOM = 11.5
export const FOCUS_ZOOM = 13
const FIT_BOUND_OPTIONS = {
    padding: 32,
    maxZoom: FOCUS_ZOOM
}

export async function mapInit(apiKey, options) {
    mapboxgl.accessToken = apiKey

    const map = await loadMap(options)
    return map;
}


export function addPoints(map, points) {

    addImageToMap(map, "marker-standard", "/dist/mapbox/marker/standard.svg");
    // addImageToMap(map, "top-trail", "/dist/mapbox/marker/top-trail.svg");

    addPointsLayer(map, points)
    // addPointsMarkers(map, points)

}

export function addDraggableMarker(map) {
    const marker = new mapboxgl.Marker({anchor: 'bottom', draggable: true})
    marker.setLngLat(map.getCenter()).addTo(map)

    return marker
}

export function calculateBounds(points) {
    if (points.length == 0) return undefined;

    let lats = points.map(p => p.lat)
    let lons = points.map(p => p.lon)
    return [
        { lon: Math.min(...lons), lat: Math.min(...lats) },
        { lon: Math.max(...lons), lat: Math.max(...lats) }
    ]
}

function loadMap(options) {
    const map = new mapboxgl.Map({
        ...options,
        fitBoundsOptions: FIT_BOUND_OPTIONS,
        attributionControl: true
    });

    return new Promise((resolve, _reject) => {
        map.on('load', () => {
            const nav = new mapboxgl.NavigationControl();
            map.addControl(nav, 'top-right');

            const sc = new mapboxgl.ScaleControl();
            map.addControl(sc);

            // const glc = new mapboxgl.GeolocateControl();
            // map.addControl(glc);

            // map.on('click', (ev) => console.log(ev))

            // map.on('zoomstart', () => {
            //     console.log(map.getBounds().toArray());
            // });

            resolve(map)
        })
    })
}

function addImageToMap(map, imageId, imagePath)
{
    const image = new Image(35, 45);
    image.onload = function() {
        map.addImage(imageId, image)
    }, image.src = imagePath
}

function addPointsMarkers(map, points) {
    for (let point of points) {
        const el = document.createElement('div')

        new mapboxgl.Marker().setLngLat(point)
            .addTo(map)
    }
}

function addPointsLayer(map, points) {
    let data = {
        type: 'FeatureCollection',
        features: points.map(pt => pt.toGeoJsonFeature())
    }

    map.addSource('points', {type: 'geojson', data})
    map.addLayer({
        id: 'points',
        type: 'symbol',
        source: 'points',
        layout: {
            "icon-allow-overlap": !0,
            "icon-anchor": "bottom",
            // "icon-image": "marker-standard"
            "icon-image": ["match", 
                ["get", "category"], 
                "submit", "marker-standard", 
                "marker-standard"
            ]
          }
    });

    // When a click event occurs on a feature in the places layer, open a popup at the
    // location of the feature, with description HTML from its properties.
    map.on('click', 'points', (e) => {
        // Copy coordinates array.
        const coordinates = e.features[0].geometry.coordinates.slice();
        const description = e.features[0].properties.name;
        const id = e.features[0].properties.ptid;

        const content = document.querySelector('[data-id="' + id + '"]');
        // const content = document.getElementById(id);
        

        console.log(content);

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        new mapboxgl.Popup({
                offset: [0, -25],
                closeButton: false
            })
            .setLngLat(coordinates)
            .setHTML(
                '<div class="fell-card fell-card--v fell-card--popup">' +
                content.innerHTML +
                '</div>'
            )
            .addTo(map);
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    map.on('mouseenter', 'points', () => {
        map.getCanvas().style.cursor = 'pointer';
    });

    // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'points', () => {
        map.getCanvas().style.cursor = '';
    });
}
